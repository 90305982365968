var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-card",
            { attrs: { title: "Purchase Order Create" } },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.form } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.purchaseOrderNumber.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.purchaseOrderNumber
                                        .decorator,
                                    expression:
                                      "formRules.purchaseOrderNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.purchaseOrderNumber.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.purchaseOrderNumber
                                      .placeholder
                                  ),
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.purchaseRequisitionNumber.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.purchaseRequisitionNumber
                                        .decorator,
                                    expression:
                                      "formRules.purchaseRequisitionNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  name:
                                    _vm.formRules.purchaseRequisitionNumber
                                      .name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.purchaseRequisitionNumber
                                      .placeholder
                                  ),
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.purchaseOrderType.label
                                )
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value:
                                        _vm.formRules.purchaseOrderType
                                          .decorator,
                                      expression:
                                        "formRules.purchaseOrderType.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.purchaseOrderType.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.purchaseOrderType
                                        .placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading:
                                      _vm.loading.loadingPurchaseOrderType,
                                    allowClear: true,
                                    disabled: _vm.isNew ? false : true
                                  },
                                  on: {
                                    search: function(value) {
                                      return _vm.getPurchaseOrderType(value)
                                    },
                                    change: function(value) {
                                      return _vm.handleChangePurchaseOrderType(
                                        value,
                                        false
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.dataPurchaseOrderType, function(
                                  data,
                                  index
                                ) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: index,
                                      attrs: { value: data.value }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.value) + " "
                                            )
                                          ]),
                                          _vm._v(" " + _vm._s(data.value) + " ")
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.branch.label)
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: _vm.formRules.branch.decorator,
                                      expression: "formRules.branch.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.branch.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.branch.placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading: _vm.loading.loadingBranch,
                                    allowClear: true,
                                    disabled: _vm.isNew ? false : true
                                  },
                                  on: {
                                    search: function(val) {
                                      return _vm.debounce(function() {
                                        return _vm.getBranch(val)
                                      }, 400)
                                    },
                                    change: _vm.handleChangeBranch
                                  }
                                },
                                _vm._l(_vm.dataBranch, function(data, index) {
                                  return _c(
                                    "a-select-option",
                                    { key: index, attrs: { value: data.id } },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.name) + " "
                                            )
                                          ]),
                                          _vm._v(" " + _vm._s(data.name) + " ")
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.requester.label)
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: _vm.formRules.requester.decorator,
                                      expression:
                                        "formRules.requester.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.requester.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.requester.placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading: _vm.loading.loadingRequester,
                                    allowClear: true,
                                    disabled: _vm.isNew ? false : true
                                  },
                                  on: {
                                    search: function(val) {
                                      return _vm.debounce(function() {
                                        return _vm.getRequester(val)
                                      }, 400)
                                    }
                                  }
                                },
                                _vm._l(_vm.dataRequester, function(
                                  data,
                                  index
                                ) {
                                  return _c(
                                    "a-select-option",
                                    { key: index, attrs: { value: data.id } },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.fullName) + " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " + _vm._s(data.fullName) + " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: { label: _vm.$t(_vm.formRules.date.label) }
                            },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.date.decorator,
                                    expression: "formRules.date.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.date.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.date.placeholder
                                  ),
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  disabled: _vm.disabledForm
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.supplierName.label)
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value:
                                        _vm.formRules.supplierName.decorator,
                                      expression:
                                        "formRules.supplierName.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.supplierName.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.supplierName.placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading: _vm.loading.loadingSupplier,
                                    allowClear: true,
                                    disabled: _vm.disabledForm
                                  },
                                  on: {
                                    search: function(val) {
                                      return _vm.debounce(function() {
                                        return _vm.getSupplier(val)
                                      }, 400)
                                    },
                                    change: _vm.handleChangeSupplier
                                  }
                                },
                                _vm._l(_vm.dataSupplier, function(data, index) {
                                  return _c(
                                    "a-select-option",
                                    { key: index, attrs: { value: data.id } },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.fullName) + " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " + _vm._s(data.fullName) + " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.supplierBillAddress.label
                                )
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value:
                                        _vm.formRules.supplierBillAddress
                                          .decorator,
                                      expression:
                                        "formRules.supplierBillAddress.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name:
                                      _vm.formRules.supplierBillAddress.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.supplierBillAddress
                                        .placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    allowClear: true,
                                    disabled: _vm.disabledForm
                                  }
                                },
                                _vm._l(_vm.dataSupplierBillAddress, function(
                                  data,
                                  index
                                ) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: index,
                                      attrs: { value: data.address }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.address) + " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " + _vm._s(data.address) + " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.currency.label)
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: _vm.formRules.currency.decorator,
                                      expression: "formRules.currency.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.currency.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.currency.placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading: _vm.loading.loadingCurrency,
                                    allowClear: true,
                                    disabled: _vm.disabledForm
                                  },
                                  on: {
                                    search: function(val) {
                                      return _vm.debounce(function() {
                                        return _vm.getCurrency(val)
                                      }, 400)
                                    },
                                    change: _vm.handleChangeCurrency
                                  }
                                },
                                _vm._l(_vm.dataCurrency, function(data, index) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: index,
                                      attrs: { value: data.currencyCode }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.currencyCode) +
                                                " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(data.currencyCode) +
                                              " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.currencyFrom !== "IDR"
                            ? _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.rate.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.rate.decorator,
                                        expression: "formRules.rate.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.formRules.rate.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.rate.placeholder
                                      ),
                                      disabled: _vm.disabledForm
                                    },
                                    on: {
                                      blur: function(val) {
                                        return _vm.onBlur(val, "rate")
                                      },
                                      focus: function(val) {
                                        return _vm.onFocus(val, "rate")
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-form-item",
                            {
                              attrs: { label: _vm.$t(_vm.formRules.tax.label) }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: _vm.formRules.tax.decorator,
                                      expression: "formRules.tax.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.tax.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.tax.placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading: _vm.loading.loadingTax,
                                    allowClear: true,
                                    disabled: _vm.disabledForm
                                  },
                                  on: {
                                    search: function(val) {
                                      return _vm.debounce(function() {
                                        return _vm.getTax(val)
                                      }, 400)
                                    },
                                    change: _vm.handleChangeTax
                                  }
                                },
                                _vm._l(_vm.dataTax, function(data, index) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: index,
                                      attrs: { value: data.value }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.value) + " "
                                            )
                                          ]),
                                          _vm._v(" " + _vm._s(data.value) + " ")
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.refNumber.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.refNumber.decorator,
                                    expression: "formRules.refNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.refNumber.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.refNumber.placeholder
                                  ),
                                  disabled: _vm.disabledForm
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              staticStyle: { "margin-top": "1rem" },
                              attrs: {
                                label: _vm.$t(_vm.formRules.attachment.label),
                                help:
                                  "Maximum file size is 5MB and accepted file extension is PDF, JPG, PNG, XLSX"
                              }
                            },
                            [
                              _c(
                                "a-upload",
                                {
                                  attrs: {
                                    name: "file",
                                    multiple: false,
                                    method: "post",
                                    "file-list": _vm.fileList,
                                    customRequest: _vm.customRequest,
                                    "before-upload": _vm.beforeUpload,
                                    accept: ".pdf, .png, .jpg, .xlsx",
                                    disabled: _vm.disabledForm
                                  },
                                  on: { change: _vm.handleChange }
                                },
                                [
                                  _c(
                                    "a-button",
                                    { attrs: { disabled: false } },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "upload" }
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_upload")) + " "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          !_vm.isNew
                            ? _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.note.label)
                                  }
                                },
                                [
                                  _c("a-textarea", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.note.decorator,
                                        expression: "formRules.note.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.formRules.note.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.note.placeholder
                                      ),
                                      "auto-size": { minRows: 2, maxRows: 6 },
                                      disabled: ""
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.status.label)
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: _vm.formRules.status.decorator,
                                      expression: "formRules.status.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.status.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.status.placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading: _vm.loading.loadingStatus,
                                    allowClear: true,
                                    disabled: ""
                                  },
                                  on: {
                                    search: function(val) {
                                      return _vm.debounce(function() {
                                        return _vm.getStatus(val)
                                      }, 400)
                                    }
                                  }
                                },
                                _vm._l(_vm.dataStatus, function(data, index) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: index,
                                      attrs: { value: data.value }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.value) + " "
                                            )
                                          ]),
                                          _vm._v(" " + _vm._s(data.value) + " ")
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.arrivalEstimated.label
                                )
                              }
                            },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.arrivalEstimated.decorator,
                                    expression:
                                      "formRules.arrivalEstimated.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.arrivalEstimated.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.arrivalEstimated.placeholder
                                  ),
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  disabled: _vm.disabledForm
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.description.label)
                              }
                            },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.description.decorator,
                                    expression:
                                      "formRules.description.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.description.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.description.placeholder
                                  ),
                                  "auto-size": { minRows: 2, maxRows: 6 },
                                  disabled: _vm.disabledForm
                                },
                                on: { change: _vm.handleChangeDescription }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-divider"),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabledForm
                              },
                              on: { click: _vm.handleAddRow }
                            },
                            [_vm._v("Add Row")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "danger",
                                disabled: _vm.disabledForm
                              },
                              on: { click: _vm.handleDeleteRow }
                            },
                            [_vm._v("Delete Row")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("TableCustom", {
                        attrs: {
                          dataSource: _vm.dataSource,
                          columns: _vm.columnsTable,
                          paginationcustom: false,
                          defaultPagination: {
                            showTotal: function(total) {
                              return _vm.$t("lbl_total_items", { total: total })
                            },
                            showSizeChanger: true
                          },
                          scroll: { x: "calc(100px + 70%)", y: 700 },
                          onSelectChange: _vm.onSelectChange,
                          selectedRowKeys: _vm.selectedRowKeys,
                          handleSearchSelectTable: _vm.handleSearchSelectTable,
                          handleSelect: _vm.handleSelect,
                          handleInput: _vm.handleInput,
                          addNew: _vm.addNew
                        },
                        on: { "on-addData": _vm.handleAddData }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: {
                            "margin-bottom": "1rem",
                            "margin-top": "1rem"
                          }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: { color: "rgba(0, 0, 0, 0.85)" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(_vm.formRules.totalQty.label)
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" Gross Amount ")
                                      ]),
                                      _c(
                                        "sup",
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "info-circle" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 14 } },
                            [
                              _c("a-input", {
                                attrs: {
                                  name: _vm.formRules.totalQty.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.totalQty.placeholder
                                  ),
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.totalQty,
                                  callback: function($$v) {
                                    _vm.totalQty = $$v
                                  },
                                  expression: "totalQty"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form",
                        _vm._b(
                          { attrs: { layout: "vertical", form: _vm.form } },
                          "a-form",
                          _vm.formItemLayout,
                          false
                        ),
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.total.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.total.decorator,
                                    expression: "formRules.total.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.total.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.total.placeholder
                                  ),
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.totalDiscount.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.totalDiscount.decorator,
                                    expression:
                                      "formRules.totalDiscount.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.totalDiscount.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.totalDiscount.placeholder
                                  ),
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.totalTax.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.totalTax.decorator,
                                    expression: "formRules.totalTax.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.totalTax.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.totalTax.placeholder
                                  ),
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.grandTotal.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.grandTotal.decorator,
                                    expression: "formRules.grandTotal.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.grandTotal.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.grandTotal.placeholder
                                  ),
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24, align: "end" } },
                    [
                      _c(
                        "a-space",
                        [
                          _vm.isNeedApproval &&
                          _vm.$can("update", "purchase-order")
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    loading: _vm.loading.loadingReject
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Rejected")
                                    }
                                  }
                                },
                                [_vm._v(" Reject ")]
                              )
                            : _vm._e(),
                          _vm.isNeedApproval &&
                          _vm.$can("update", "purchase-order")
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.loadingApprove
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Approved")
                                    }
                                  }
                                },
                                [_vm._v(" Approve ")]
                              )
                            : _vm._e(),
                          (_vm.isDraft || _vm.isUpdated || _vm.isApproved) &&
                          _vm.$can("update", "purchase-order")
                            ? [
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: _vm.$t("pop_confirmation"),
                                      "ok-text": _vm.$t("lbl_yes"),
                                      "cancel-text": _vm.$t("lbl_no")
                                    },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.onSubmit("Cancelled")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "danger",
                                          loading: _vm.loading.loadingCancel
                                        }
                                      },
                                      [_vm._v(" Cancel ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          (_vm.isNew ||
                            _vm.isDraft ||
                            (_vm.isUpdated &&
                              _vm.actualStatusDocument !== "Need Approval")) &&
                          (_vm.$can("create", "purchase-order") ||
                            _vm.$can("update", "purchase-order"))
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.loadingSubmit
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Submited")
                                    }
                                  }
                                },
                                [_vm._v(" Submit ")]
                              )
                            : _vm._e(),
                          (_vm.isDraft ||
                            _vm.isUpdated ||
                            _vm.isNeedApproval) &&
                          _vm.$can("update", "purchase-order")
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.loadingUpdate
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Updated")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isUpdated ? "Save Update" : "Update"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.isNew && _vm.$can("create", "purchase-order")
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.loadingDraft
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Draft")
                                    }
                                  }
                                },
                                [_vm._v(" Save as Draft ")]
                              )
                            : _vm._e(),
                          !_vm.isNew &&
                          !_vm.isDraft &&
                          !_vm.isUpdated &&
                          !_vm.isNeedApproval
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.loadingPrint
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Print")
                                    }
                                  }
                                },
                                [_vm._v(" Print ")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                centered: false,
                dialogstyle: { top: "20px" },
                maskClosable: false,
                width: 800,
                title: "Add Data",
                showmodal: _vm.modalOpen,
                idmodal: "modal1"
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
                [
                  _c("TableCustom", {
                    attrs: {
                      idtable: "table2",
                      dataSource: _vm.dataListItemsModal,
                      columns: _vm.columnsTableModal,
                      scroll: { x: "calc(100px + 50%)" },
                      paginationcustom: false,
                      defaultPagination: true,
                      loading: _vm.loadingTableModal,
                      handleInput: _vm.handleInputModal
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footermodal" }, slot: "footermodal" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      attrs: { type: "danger" },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                  ),
                  _vm.isNew || _vm.isUpdated
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: { type: "primary" },
                          on: { click: _vm.saveDataModal }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }